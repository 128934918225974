<template>
<!-- 企业认领 -->
  <div class="BGS">
      <Header></Header>
      <QEntryEnterprises></QEntryEnterprises>
      <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header"
import QEntryEnterprises from "@/components/QEntryEnterprises"
import Footer from "@/components/Footer"
export default {
    data(){
        return{

        }
    },
    methods:{

    },

    components:{
      Header,
      QEntryEnterprises,
      Footer
    },
}
</script>

<style scoped>
.BGS {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: #f5f5f5;
}
</style>